import React, { createContext, useMemo } from 'react';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';
import { createSDK } from '../services/split.io';

const InitSplitContext = createContext(null);

const getAccountIdFromUrl = () => {
    const { pathname } = window.location;
    if (pathname?.includes('account')) {
        const id = pathname.replace(/\D/g, '');
        return id || null;
    }
    return null;
};

const InitSplitProvider = ({ children }) => {
    const accountId = getAccountIdFromUrl(); // Get account ID from URL
    const accounts = useSelector((state) => state?.accounts?.accounts); // Get all accounts
    const networkID = accountId && accounts?.find((account) => Number(account?.id) === Number(accountId))?.networkId;
    const adminNetworkId = useSelector((state) => state?.profile?.meta?.networkId); // Get admin network ID
    const splitKey = networkID || adminNetworkId; // Use fallback if no param in URL

    const config = useMemo(() => createSDK(splitKey), [splitKey]);

    return (
        <InitSplitContext.Provider value={{ config }} key={splitKey}>
            <SplitFactoryProvider config={config} updateOnSdkUpdate updateOnSdkTimedout={false} key={splitKey}>
                {children}
            </SplitFactoryProvider>
        </InitSplitContext.Provider>
    );
};

export default InitSplitProvider;
